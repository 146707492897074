import React, {useState, useRef, useEffect, version} from 'react'
// import preval from 'preval.macro'
import './App.css';
import {
  InformationCircleIcon,
  ChartBarIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { useAlert } from './context/AlertContext'
import { AlertContainer } from './components/alerts/AlertContainer'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  DUPLICATE_WORD_MESSAGE,
  WRONG_SPOT_MESSAGE
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  solution,
  index,
  findFirstUnusedReveal,
  tomorrow, 
  unicodeLength,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import Countdown from 'react-countdown';
function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [currentGuess, setCurrentGuess] = useState('')
  // const [indexClue, setIndexClue] = useState({index: index, clue: solution[index]})
  const [indexClue, setIndexClue] = useState({index: index, clue:solution[index]})
  const [openGuesses, toggleOpenGuesses] = useState(false)
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [guesses, setGuesses] = useState(()=>{
    const loaded = JSON.parse(loadGameStateFromLocalStorage())

    if (!loaded) {
      return []
    }
   
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    
    return loaded.guesses
  })
  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(true)

  const scrollRef = useRef(null)

  const currentSolution = useRef(null)


  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    console.log('on load')
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    } 
    refreshAndRecache();
    setInterval(()=>{
      refreshAndRecache();
    }, [5000])   
    currentSolution.current = solution;

  }, [])

  const refreshAndRecache = () => {

    // console.log('checking cache', guesses, solution)
    let localStorage = JSON.parse(loadGameStateFromLocalStorage())
    let today = new Date().toDateString();
    if (!loadGameStateFromLocalStorage || !guesses.length) {
      return;
    } 

    if (currentSolution.current && currentSolution.current !== solution) {
      handleReset()
      currentSolution.current = solution
    }


    if (localStorage.date !== today || !localStorage.date) {
      handleReset()
    }
    if (localStorage.version !== process.env.REACT_APP_BUILD_VERSION) {
      handleReset()
    }
  }

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    // console.log('new guesses', guesses)
    let version = process.env.REACT_APP_BUILD_VERSION
    let date = new Date().toDateString();
    var objDiv = document.getElementById("guesses-container")

    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;          
    }
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
    saveGameStateToLocalStorage({ guesses, solution, index, version, date })
    
  }, [guesses])


  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH

      showSuccessAlert(winMessage, {
        // onClose: () => setIsStatsModalOpen(true),
      })
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)
    }

  }, [isGameWon, isGameLost, showSuccessAlert])

  const handleReset = () => {
    console.log('resetting')
    let today = new Date().toDateString();

    setIsGameWon(false)
    setIsStatsModalOpen(false); 
    saveGameStateToLocalStorage({ guesses: [], solution, index, today })
    setGuesses([])
    window.location.reload(true);

  }

  const onChar = (value) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon) {
      return
    }

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (guesses.includes(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(DUPLICATE_WORD_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }


    if (solution[index] !== currentGuess[index]) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WRONG_SPOT_MESSAGE(solution[index], index+1), {
        onClose: clearCurrentRowClass,
      })
    }
  

    // enforce hard mode - all guesses must contain all previously revealed letters
    // if (isHardMode) {
    const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
    if (firstMissingReveal) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(firstMissingReveal, {
        onClose: clearCurrentRowClass,
      })
    }
    // }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    const winningWord = isWinningWord(currentGuess)
    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')


      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

    }
  }


  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8 relative">
      <div className="flex w-80 mx-auto items-center mb-8 mt-10">
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {GAME_TITLE}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        />
      </div>
      <Grid
        guesses={guesses}
        indexClue={indexClue}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
        scrollRef={scrollRef}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleReset={handleReset}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />
      <AlertContainer />
      <Countdown
        className="hidden"
        date={tomorrow}
        onComplete={handleReset}
        daysInHours={true}
      />
    </div>
  );
}

export default App;
