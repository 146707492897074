import { MAX_WORD_LENGTH } from '../../constants/settings'
import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'

export const CurrentRow = ({ guess, indexClue, position, isRevealing, className }) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))
  const classes = `flex justify-center pb-6 ${className}`

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} position={i} indexClue={indexClue} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} indexClue={indexClue} isRevealing={isRevealing} position={splitGuess.length+i}/>
      ))}
    </div>
  )
}
