import React from 'react'
import Letter from './Letter'

export default function CorrectWord({
    correct, 
    statuses,
    isRevealing, 
    position, 
    scrollRef = null
}) {
  return (
    <div ref={scrollRef} className='flex items-center mr-6 pb-2'>
      <div className='text-3xl text-right flex-1 font-bold pr-2 dark:text-white'>{position}.</div>
      <div className='flex flex-1 justify-center ' >
        {correct.split('').map((letter, i)=>{
            return <Letter 
                key={i}
                value={letter}
                status={statuses[i]}
                position={i}
                isRevealing={isRevealing}
            />
        })}
        </div>
    </div>
  )
}
