import { MAX_CHALLENGES } from '../../constants/settings'
import { CurrentRow } from './CurrentRow'
import CorrectBar from './CorrectBar'



export const Grid = ({
  guesses,
  currentGuess,
  isRevealing,
  indexClue, 
  currentRowClassName,
  scrollRef
}) => {
  const empties =
    guesses.length < MAX_CHALLENGES - 1
      ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
      : []

  return (
    <div className="pb-6">
      {/* {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < MAX_CHALLENGES && (
        <CurrentRow guess={currentGuess} className={currentRowClassName} />
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))} */}
      <CurrentRow guess={currentGuess} indexClue={indexClue} isRevealing={isRevealing} className={currentRowClassName} />
      <CorrectBar guesses={guesses} scrollRef={scrollRef}/>
    </div>
  )
}
