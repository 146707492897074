import { MAX_CHALLENGES } from '../constants/settings'
import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

// In stats array elements 0-5 are successes in 1-6 trys

export const addStatsForCompletedGame = (
  gameStats,
  count
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats }

  if (count+1 > gameStats.bestScore || gameStats.bestScore === 0) {
    stats.bestScore = count+1
  } 

  if (count+1 < gameStats.worstScore || gameStats.worstScore === 0) {
    stats.worstScore = count+1
  }

  stats.average = ((stats.average*stats.totalGames) + (count+1)) / (stats.totalGames+1)
  stats.totalGames += 1
    
  saveStatsToLocalStorage(stats)
  return stats
}

const defaultStats = {
  bestScore: 0, 
  worstScore: 0,
  totalGames: 0,
  average: 0
}

export const loadStats = () => {
  return loadStatsFromLocalStorage() || defaultStats
}
