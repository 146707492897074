import React from 'react'
import CorrectWord from './CorrectWord'
import { getGuessStatuses } from '../../lib/statuses'

export default function GuessesBar({
  guesses, 
  scrollRef
}) {
  let correctWords = 0

  return (
    <div className='mx-auto h-56 border-dashed border-2 border-slate-400 max-w-sm w-full overflow-y-none relative'>
        <div id="guesses-container" className='flex flex-col-reverse w-full max-h-full p-2 overflow-y-scroll no-scrollbar'>
          {
          guesses.slice(0).reverse().map((guess, i)=> {
          const statuses = getGuessStatuses(guess)
            let position = guesses.length - correctWords
            correctWords += 1

            return ( 
              <CorrectWord 
                key={i} 
                correct={guess}
                position={position}
                statuses={statuses}
                scrollRef={position === guesses.length ? scrollRef : null}
                />
            )
          })}
        </div>  
    </div>
  )
}
