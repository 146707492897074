import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
// import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import classNames from 'classnames'

import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  TOTAL_TRIES_TEXT,
  SUCCESS_RATE_TEXT,
  CURRENT_STREAK_TEXT,
  BEST_STREAK_TEXT,
} from '../../constants/strings'
import { useEffect } from 'react'

const StatItem = ({
  label,
  value,
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  )
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
}) => {

  // useEffect(()=> {
  //   console.log('from stats modal', tomorrow)
  // }, [])

  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {isGameWon && <div className="flex justify-center items-center gap-5 dark:text-white">
        <h4 className="text-1xl">You used: </h4>
        <div className="text-3xl"><strong>{guesses.length}</strong> words!</div>
      </div>}
      <div className="flex justify-center my-2">
        <StatItem label={'Total Games'} value={gameStats.totalGames ? gameStats.totalGames : 0} />
        <StatItem label={'Best Score'} value={gameStats.bestScore?gameStats.bestScore : '-'} />
        <StatItem label={'Worst Score'} value={gameStats.worstScore ? gameStats.worstScore : '-'} />
        <StatItem label={'Average'} value={gameStats.average ? gameStats.average.toFixed(2) : '-'} />
      </div>

      {(isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
              onComplete={handleClose}
            />
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                guesses,
                isGameLost,
                isHardMode,
                isDarkMode,
                isHighContrastMode
              )
              handleShare()
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
    </BaseModal>
  )
}
