import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'

export const shareStatus = (
  guesses,
  lost,
  isHardMode,
  isDarkMode,
  isHighContrastMode
) => {

  if (!navigator.clipboard) {
     // text area method
     let textArea = document.createElement("textarea");
     textArea.value = 'testing'
     // make the textarea out of viewport
     textArea.style.position = "fixed";
     textArea.style.left = "-999999px";
     textArea.style.top = "-999999px";
     document.body.appendChild(textArea);
     textArea.focus();
     textArea.select();
     return new Promise((res, rej) => {
         // here the magic happens
         document.execCommand('copy') ? res() : rej();
         textArea.remove();
     });
  }
  navigator.clipboard.writeText(
    `${GAME_TITLE} #${solutionIndex+1} \nScored ${
      guesses.length
    } words\n\n` +
      generateEmojiGrid(guesses, getEmojiTiles(isDarkMode, isHighContrastMode))
  )
}

export const generateEmojiGrid = (guesses, tiles) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}

const getEmojiTiles = (isDarkMode, isHighContrastMode) => {
  let tiles = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')
  tiles.push(isHighContrastMode ? '🟦' : '🟨')
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}
